import React, { useState, useEffect} from "react";
import { Container, Row, Col} from "react-bootstrap";
import api from "../actions/riveroapi";

import SEO from "../components/seo";
import Layout from "../components/layouts/layoutSinMenu";
import Image from "../components/widgets/basico/image";
import FormularioTallerServicio from "../components/widgets/formularios/FormularioTallerServicioSF";

export default function CitaServicioNissan(props){
    const path = props.location.pathname.split("/");
    const [Nombre, setNombre] = useState([]);
    const [IdInventarioMM, setIdInventarioMM] = useState("");
    const [NombreCompleto, setNombreCompleto] = useState("");
    const [Celular, setCelular] = useState("");
    const [Correo, setCorreo] = useState("");
    const [Modelo, setModelo] = useState("");


    const parameters = props.location.search;
  

    useEffect(() => {
                let splitUrl = window.location.pathname.split("/");
                console.log( splitUrl[2]);
            
                api.getSFCitaTallerInfo({ sfId:splitUrl[2],empresa: "nissan" }).then((resp) => {
                    console.log(resp.data);
                    if(resp.data){
                        setNombre(resp.data[0].Account.FirstName);
                        setNombreCompleto(resp.data[0].Account.Name);
                        setCelular(resp.data[0].Account.MobilePhone__c);
                        setCorreo(resp.data[0].Account.PersonEmail);
                        setIdInventarioMM(resp.data[0].IdInventarioMM__c);
                        setModelo(resp.data[0].Modelo__c+" "+resp.data[0].Anio__c);
                    }
                
                 }).catch((error) => console.log(error));
           
    });


    return(
        <Layout selectedMenuItem="cita-de-servicio" parameters={parameters}>
            <SEO
                title="Servicio Nissan - Taller Nissan - Taller Rivero Servicio"
                description="Grupo Rivero - Servicio Nissan - Taller Nissan - Agencia Nissan Mty - Servicio Nissan Costos - Servicio March, Versa, Sentra, Kicks, Xtrail, Altima"
                keywords="Servicio Nissan, taller de servicio automotriz, taller nissan, servicio nissan"
                path="/cita-de-servicio"
            />
            <Container>
                <div className="bg-white">
                    <Row style={{display: 'flex', flexDirection: 'row-reverse'}}>
                        <Col lg={4} md={4} sm={12} className="pb-4">
                        
                                <h1 className="pt-2" style={{ fontSize: 25 }}> Hola {Nombre}!</h1>
                                <h4 style={{ fontSize: 18 }}>Agenda aquí tu Cita de Servicio</h4>
                                <Row>
                                    <Col>
                                        <FormularioTallerServicio IdInventarioMM={IdInventarioMM} Nombre={NombreCompleto} celular={Celular} correo={Correo} modelo={Modelo}/>
                                    </Col>
                                </Row>
                            
                        </Col>
                        <Col lg={8} md={8} sm={12}>
                            { <Image fileName="banner-nissan-rivero-centro-de-servicio.jpg" alt="Taller de servicio"/>}
                            <h1 style={{ fontSize: 25 }} className="p-4">
                                Cita en Taller de Servicio Nissan
                            </h1>
                            <div className="p-4">
                                <p>
                                En Rivero Nissan contamos con equipo de trabajo altamente capacitado  y te daremos un trato de primos, sabemos lo  esencial que es mantener nuestros vehículos en óptimas condiciones y garantizar su funcionamiento correcto. 
                                </p>
                                <p>
                                Por eso en Rivero te ofrecemos:
                                </p>
                                <ul>
                                <li>
                                Agendar fácilmente tu cita y cuidar los tiempos de entrega de tu auto
                                </li>
                                <li>Darte la mejor mano de obra certificada.</li>
                                <li>
                                    Reparar o dar servicio a tu unidad con el mejor equipo y
                                    tecnología
                                </li>
                                <li>Cuidar siempre la garantía de tu auto</li>
                                </ul>
                                <p>Agenda tu cita de servicio con nosotros</p>
                            </div>
                        </Col>
                    </Row>

                    {/*<Row className="pt-4">
                        <Col className="px-5">
                            <h4>Promociones</h4>
                            <center>
                                <CatalogoPromos promoType="taller" props={props}/>
                            </center>
                        </Col>
    </Row>*/}
                </div>
            </Container>
        </Layout>
    )

}