import React, { useState, useEffect } from "react";
import { Link, withPrefix } from "gatsby";
import { Container, Navbar, Nav, NavDropdown, NavItem, Button } from "react-bootstrap";
import ModalCita from "./widgets/modalContactanos";
import { Helmet } from "react-helmet";
import TagManager from 'react-gtm-module';

import Image from "./widgets/basico/image";
import waIcon from "../images/icons/whats_icon.png";
import mailIcon from "../images/icons/correo_icon.png";
import telIcon from "../images/icons/tel_icon.png";

export default function HeaderSinMenu ({selectedMenuItem, navActivo, parameters, marca}) {

    const [cita, setCita] = useState(false);
    const [ colorHeader, setColorHeader ] = useState();
    const [ imgHeader, setImageHeader ] = useState();
    const [ empresa, setEmpresa ] = useState();

    const [ contacto, setContacto ] = useState();

    const [ contactaIcon, setContactaIcon ] = useState({
        telefono: "",
        whatsapp: "",
    });

    const [ contactaIconNissan, setContactaIconNissan ] = useState({
        telefono: "",
        whatsapp: "",
    });
    
    const tallerOptions = ["taller-servicio", "taller-colision", "refacciones"];
    const weOptions = ["quienes-somos", "horario", "blog"];
    const promoOptions = ["promo-cars", "promo-accesorios", "promo-taller"];
    const [ windowSplit, setWindowSplit ] = useState();

    const NavDropItem = ({ children, selected, title, to }) => {
        return (
            <NavDropdown.Item
                as={Link}
                to={withPrefix(to)}
                className={selected ? "selected text-primary" : "text-header-inactivo"}
            >
                {title || null}
                {children || null}
            </NavDropdown.Item>
            );
    };
    //console.log("Estamos en el HEADER, los parametros: ", parameters);
    useEffect(() => {
    
        let splitUrl = window.location.pathname.split("/");
        setWindowSplit(splitUrl[1]);
        //console.log("SplitUtl, ", splitUrl[1]);
        
        if(navActivo === "Principal"){
            setEmpresa("principal");
            setColorHeader("barraMenuNissan");
            setImageHeader("nissan_header_logo.png");
            setContactaIconNissan({
                ...contactaIconNissan,
                whatsapp: "boton_nissan_header_whatsapp.png",
                telefono: "boton_nissan_header_telefono.png"
            })
        }else if(splitUrl[1] === "nissan" || marca === "nissan") {
            setEmpresa("nissan");
            setColorHeader("barraMenuNissan");
            setImageHeader("nissan_header_logo.png");
            setContactaIconNissan({
                ...contactaIconNissan,
                whatsapp: "boton_nissan_header_whatsapp.png",
                telefono: "boton_nissan_header_telefono.png"
            })
        }
        else {
            setEmpresa("principal");
            setColorHeader("barraMenuNissan");
            setImageHeader("nissan_header_logo.png");
            setContactaIcon({
                ...contactaIconNissan,
                whatsapp: "boton_nissan_header_whatsapp.png",
                telefono: "boton_nissan_header_telefono.png"
            })
        }

    }, [])
    //console.log("Tengo la empresa: ", marca);
    //Detector parametros url que tendrá durante la página

    let tagManagerArgs = {
        gtmId: 'GTM-W7HQJGC6', //Este ID se obtiene en el admin de GTM
        dataLayer: {
            'event': contacto,
        }
    }
 
    useEffect (() => {

        if (tagManagerArgs.dataLayer.event != undefined){
            console.log(tagManagerArgs.dataLayer.event);
            TagManager.initialize(tagManagerArgs);
        }
    }, [tagManagerArgs]);

    function sendData(num){ 
        setContacto(num);
    }

    return(
        
        <React.Fragment>


{/*         <noscript>
            <iframe
            src="https://www.googletagmanager.com/gtag/js?id=G-Z8WVEC2V1Z"
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
            ></iframe>
        </noscript> */}
        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W7HQJGC6" height="0" width="0" style={{"display": "none", "visibility": "hidden"}}></iframe></noscript>
        <noscript> <img height="1" width="1" src="https://www.facebook.com/tr?id=1469889453605563&ev=PageView&noscript=1"/> </noscript>
        <Helmet>
            <meta name="facebook-domain-verification" content="spzhwiyaarvxat6p7tc2r0qu4lexbw" />
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-Z8WVEC2V1Z"></script>

        </Helmet>
        
            <Helmet
                script={[
                    {
                        innerHTML: `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-Z8WVEC2V1Z');
                        `,
                    },
                ]}
            ></Helmet>
            <Helmet
                    script={[
                        {
                            innerHTML: `
                            !function(f,b,e,v,n,t,s)
                            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                            n.queue=[];t=b.createElement(e);t.async=!0;
                            t.src=v;s=b.getElementsByTagName(e)[0];
                            s.parentNode.insertBefore(t,s)}(window,document,'script',
                            'https://connect.facebook.net/en_US/fbevents.js');
                            fbq('init', '1469889453605563');
                            fbq('track', 'PageView');
                            `,
                        },
                    ]}
            ></Helmet>
            <Helmet
                    script={[
                        {
                            innerHTML: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-W7HQJGC6');
                            `,
                        },
                    ]}
            ></Helmet>
            <Navbar expand="lg" fixed="top" variant="dark" id={navActivo === "Principal" ? "barritaDebajo" : `${colorHeader}`}>
                <Container style={{height: '100%'}}>
                    <Navbar.Brand>
                 <Link to={parameters ? "/" + parameters : "/"} id="headerImageBrand">
                          <Image
                                className="d-md-block d-none"
                                fileName={imgHeader}
                                alt="banner"
                                imgStyles={{position: 'absolute', objectFit: 'contain', width: '100%'}}
                            />
                        </Link>
                    </Navbar.Brand>


                  
                </Container>
            </Navbar>
            <ModalCita show={cita} onHide={() => setCita(false)}/>
        </React.Fragment>
    )

} 