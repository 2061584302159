import React from "react"
import Header from "../headerSinMenu"
import Footer from "../footer";
import FooterMobile from "../footerMobile";
/*import "../../styles/global.css"*/

/////////// Layout Home ////////////////////

export default function LayoutSinMenu({selectedMenuItem, children, parameters, marca }) {
  /* console.log("Layout Home"); */
  return (
    <>
        <Header selectedMenuItem={selectedMenuItem} parameters={parameters} marca={marca}/>
        <div className="ContainerLayout">
          {children}
          <seez-sdk-seezar-modal></seez-sdk-seezar-modal>
        </div>
        <FooterMobile/>
        <Footer marca={marca}/>
    </>
  )
}